import request from '@/service/request'
// 自定义缴费

/* 自定义缴费模板 page接口
*/
export const pageBatchPayModelApi = (params) => {
  return request.post('/stu/customPay/batch/page', params)
}

/*  自定义缴费类型  根据batchId获取绑定的学生 */
export const pageStuByBatchIdApi = (params) => {
  return request.post('/stu/customPay/pageDetail', params)
}

/*  自定义缴费类型  绑定学生 接口 */
export const bindStuByBatchPayModelApi = (params) => {
  return request.post('/stu/customPay/batch/bindStu', params)
}

/*  自定义缴费类型  解绑学生 接口 */
export const unbindStuByBatchPayModelApi = (id) => {
  return request.post(`/stu/customPay/batch/unBind/${id}`)
}

/*  自定义缴费类型  根据id获取数据 接口 */
export const getBatchPayModelByIdApi = (id) => {
  return request.post(`/stu/customPay/batch/getById/${id}`)
}

/*  自定义缴费类型  根据id删除 接口 */
export const delBatchPayModelApi = (id) => {
  return request.post(`/stu/customPay/batch/delete/${id}`)
}

/*  自定义缴费类型  保存接口 */
export const saveBatchPayModelApi = (params) => {
  return request.post('/stu/customPay/batch/save', params)
}

/*  自定义缴费类型  推送缴费通知 、
*  id {string} 自定缴费批次的id
* */
export const sendPayMessageApi = (id) => {
  return request.post(`/stu/customPay/batch/sendMsg/${id}`)
}
