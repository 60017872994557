<!-- 批量绑定 -->
<template>
  <el-dialog
    class="batch-pay-model-bind-stu-dialog"
    title="批量绑定多个缴费项目"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="close"
    v-loading="loading"
    width="1300px"
  >
    <div v-loading="loading" class="page-container unbind-student-view flex-column oh">
      <div class="flex-not-shrink" style="padding: 10px 0 0;">
        <view-search-form
          ref="searchFormRef"
          keyword-placeholder="姓名、学号、录取编号"
          :query-info.sync="tableQuery"
          :tool-list="['college','major','class','keyword']"
          @on-search="renderTable"
          @on-clear="clearTable" />
        <p class="font-grey mt-1" style="font-size: 13px;">搜索结果: {{ tableData.length }} 人</p>
      </div>
      <!-- body -->
      <div class="flex-column unbind-student-view__body flex flex-1 oh">
        <el-table :data="tableData" empty-text="请先选择院系、专业" style="width: 100%" height="auto" border stripe
                  @selection-change="handleSelectChange">
          <el-table-column type="selection" width="50" align="center" />
          <el-table-column type="index" label="ID" width="100" align="center" />
          <el-table-column prop="idNumber" label="身份证" width="190" />
          <el-table-column prop="stuName" label="姓名" width="120" />
          <el-table-column prop="campusName" label="校区" width="150" />
          <el-table-column prop="collegeName" label="院系" width="150" />
          <el-table-column prop="majorName" label="专业" width="150" />
          <el-table-column prop="className" label="班级" min-width="150">
            <template v-slot="{row}">
              {{ row.className || '未绑定' }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="unbind-student-view__footer flex-not-shrink">
        <span class="mr-1">请选择自定义缴费批次(可多选)</span>
        <el-select class="mr-1" v-model="batchIds" filterable multiple placeholder="自定义缴费批次"
                   style="width: 50%;">
          <el-option
            v-for="{dailyTypeName, id} in batchPayTypeList"
            :label="dailyTypeName"
            :value="id"
            :key="id"
          />
        </el-select>
        <!--  以下成立的都禁用:
         1.table没有勾选
         2.没有选择班级
         3.没有选专业
         4.前面3条都达成,但是没有选择要绑定的自定义缴费批次 -->
        <el-button type="primary"
                   :disabled="!batchIds.length&&!tableQuery.classesId&&!tableQuery.majorId&&!rowCheckList.length"
                   @click="handleBatchBindStu">批量绑定
        </el-button>

      </div>
    </div>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import tableView from '@/vue/mixins/table-view'
import { getStudentListQuery } from '@/api/student'
import { bindStuByBatchPayModelApi, pageBatchPayModelApi } from '@/api/finance-manage/custom-pay-api'

export default {
  name: 'batchBindDialog',
  mixins: [dialog, tableView],
  data () {
    return {
      tableQuery: { // queryInfo
        loadFlag: true,
        collegeId: this.$store.state.login.userInfo.collegeId || null,
        keyword: null,
        majorId: null,
        classesId: null
      },
      batchPayTypeList: [],
      batchIds: [],
      rowCheckList: []
    }
  },
  mounted () {
    this.getBatchPayTypeList()
    this.$nextTick(() => {
    })
  },
  methods: {
    // 获取学生列表
    async renderTable () {
      this.clearTable()
      if ((!this.tableQuery.majorId || !this.tableQuery.classesId) && !this.tableQuery.keyword) {
        this.$message('请先选择专业或者班级或者输入学生名后再进行搜索。')
        return
      }
      if (this.tableQuery.keyword && !isNaN(this.tableQuery.keyword) && this.tableQuery.keyword.length < 8) {
        this.$message('学号/考号搜索请最少输入7位数字.')
        return
      }
      this.loading = true
      this.tableQuery.batchId = this.batchId
      try {
        const res = await getStudentListQuery(this.tableQuery)
        this.tableData = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getBatchPayTypeList () {
      try {
        const _query = {
          pageNum: 1,
          pageSize: 1000,
          type: 2
        }
        const res = await pageBatchPayModelApi(_query)
        this.batchPayTypeList = res.data.list
      } catch (e) {

      }
    },
    // table 多选控制,获取选中的id
    handleSelectChange (val) {
      while (this.rowCheckList.length) {
        this.rowCheckList.pop()
      }
      if (val.length) {
        val.forEach(({ userId }) => {
          this.rowCheckList.push(userId)
        })
      }
    },
    // 批量绑定
    handleBatchBindStu () {
      this.$confirm('是否确定绑定?').then(async () => {
        this.loading = true
        const _promise = []
        this.batchIds.map(item => {
          const _query = {
            batchId: item,
            stuList: this.rowCheckList
          }
          _promise.push(() => this.handleBingStus(_query))
        })
        await Promise.all(_promise.map(func => func()))
        this.$message.success('绑定成功!', { group: true })
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    // 绑定 当个缴费类型和学生
    async handleBingStus (query) {
      try {
        await bindStuByBatchPayModelApi(query)
      } catch (e) {
      } finally {
      }
    },
    handleClose () {
      this.$confirm('是否确定关闭弹窗?').then(() => {
        this.close()
      })
    }
  }
}
</script>

<style lang="scss">
.batch-pay-model-bind-stu-dialog {
  .el-dialog {
    margin-top: 20px !important;
    margin-bottom: 0 !important;

    .el-dialog__body {
      padding-top: 0;
    }
  }
}
</style>
