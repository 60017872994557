<!-- 缴费项目绑定学生 -->
<template>
  <el-dialog
    class='batch-pay-model-bind-stu-dialog'
    :title='`绑定学生（缴费项目：${title}）`'
    :visible.sync='show'
    :close-on-click-modal='false'
    :before-close='close'
    width='1300px'
  >
    <div style='margin: 10px 20px 0;'>
      <el-tabs v-model="dialogType">
        <el-tab-pane label='已绑定的学生' name='BindStudentView'/>
        <el-tab-pane label='批量绑定操作' name='UnbindStudentView'/>
      </el-tabs>
    </div>
    <!-- 获取了 缴费项目的id后才开始加载 -->
    <template v-if='id'>
      <component :batch-id='id' :title='title' :is='dialogType' :orga-type="title.orgaType" @on-close='handleClose' />
    </template>
    <div class='font-center' v-if="dialogType==='BindStudentView'">
      <el-button class='mt-1 mb-1' @click='handleClose'>关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import BindStudentView from '@/views/pages/financeManage/batch-pay-model/component/batch-pay-model-bind-stu-dialog/bindStudentView.vue'
import UnbindStudentView from '@/views/pages/financeManage/batch-pay-model/component/batch-pay-model-bind-stu-dialog/unbindStudentView.vue'

export default {
  name: 'batchPayModelBindStuDialog',
  components: { UnbindStudentView, BindStudentView },
  mixins: [dialog],
  data () {
    return {
      dialogType: 'BindStudentView'
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (!this.id) {
        this.$message.error('系统出错,请稍后重试！')
        this.close()
      }
    })
  },
  methods: {
    handleClose () {
      this.$confirm('是否确定关闭弹窗?').then(() => {
        this.close()
      })
    }
  }
}
</script>

<style lang='scss'>
.batch-pay-model-bind-stu-dialog {
  .el-dialog {
    margin-top: 20px !important;
    margin-bottom: 0 !important;

    .el-dialog__body {
      padding-top: 0;
    }
  }
}
</style>
