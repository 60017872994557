import request from '@/service/request'
/*  缴费类型 api */

/* 日常缴费类型 page接口
*  @params/keyword 关键字
* */
export const pageDailyPayTypeApi = (params) => {
  return request.post('/pay/dailyPayType/page', params)
}

/* 日常缴费类型 list接口
*  type {number} 1-扫码 2-自定义缴费类型
* */
export const listDailyPayTypeApi = (type) => {
  return request.post(`/pay/dailyPayType/listByType/${type}`)
}

/* 日常缴费类型 list接口 (全部)\
*  1-扫码 2-自定义缴费类型
*  包括已删除
* */
export const listAllDailyPayTypeApi = () => {
  return request.post('/pay/dailyPayType/listAll')
}

/* 日常缴费类型 删除接口
*  @params/id
* */
export const delDailyPayTypeApi = (id) => {
  return request.post(`/pay/dailyPayType/del/${id}`)
}

/* 日常缴费类型 保存接口
*  @params/number 金额
*  @params/id
*  @params/name 名称
*  @params/remarks 备注
*/
export const saveDailyPayTypeApi = (params) => {
  return request.post('/pay/dailyPayType/save', params)
}

//  生成 pdf
/* 学费 移动支付
*  @params/bankType 银行类型 ccb建行，boc中行，icbc工行
*  @params/id
*/
export const generateBillPdfApi = (params) => {
  return request.post('/pay/app/payTotalOrder/getPayBillFileUrl', params)
}

/* 学费 其他类型支付
*  @params/id
*/
export const generateOtherBillPdfApi = (id) => {
  return request.post(`/stu/stuTuitionOrder/getDownloadPayBillUrl/${id}`)
}

/* 日常费用 生成pdf文件
*  @params/id
*  @params/idNumber 身份证(dailyIdNumber)
*/
export const generateDailyPayBillPdfApi = (params) => {
  return request.post('/pay/payOrder/getDailyPayBillFileUrl', params)
}
