<template>
  <table-view
    class="bind-student-view"
    :total="total"
    :query-info.sync="queryInfo"
    v-loading="loading"
    @reload-table="renderTable">
    <template #header>
      <view-search-form
        ref="searchFormRef"
        keyword-placeholder="姓名、身份证号"
        :query-info.sync="queryInfo"
        :tool-list="['college','major','class','keyword']"
        @on-search="renderTable" />
    </template>
    <el-table :data="tableData" style="width: 100%" height="calc(100vh - 397px)" border stripe>
      <el-table-column type="index" label="ID" width="80" align="center"/>
      <el-table-column prop="idNumber" label="身份证" width="190" />
      <el-table-column prop="stuName" label="姓名" width="120" />
      <el-table-column prop="collegeName" label="院系" width="150" />
      <el-table-column prop="majorName" label="专业" width="150" />
      <el-table-column prop="className" label="班级" min-width="150">
        <template v-slot="{row}">
          {{ row.className||'未绑定' }}
        </template>
      </el-table-column>
      <el-table-column width="120" align="center">
        <template slot="header">
          <tool-tips-group tips-content="支付成功的学生无法解绑该缴费项目。">
            <span style="vertical-align: -2px">支付状态</span>
          </tool-tips-group>
        </template>
        <template slot-scope="{row}">
          {{ orderStatusList[Number(row.ordrStcd || 1) - 1].name }}
        </template>
      </el-table-column>
      <el-table-column prop="orderNo" label="订单编号" width="180" />
      <el-table-column prop="payTime" label="支付时间" width="120" />
      <el-table-column label="操作" width="100" fixed="right" align="center">
        <template v-slot="{row}">
          <!--  ordrStcd=2 支付成功,不能解绑  -->
          <el-button
            v-if="row.ordrStcd!==2"
            type="warning"
            size="mini"
            @click="handleUnbind(row)">解绑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </table-view>

</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { pageStuByBatchIdApi, unbindStuByBatchPayModelApi } from '@/api/finance-manage/custom-pay-api'
import { throttle } from 'lodash'
import { orderStatusList } from '@/views/pages/financeManage/searchOrder/component/options'

export default {
  name: 'bindStudentView',
  mixins: [tableView],
  props: {
    batchId: String // 缴费 项目 id
  },
  data () {
    return {
      orderStatusList,
      campusList: [],
      classList: [],
      payType: 1,
      queryInfo: { // queryInfo
        batchId: '',
        dailyPayTypeId: '',
        collegeId: null,
        keyword: null,
        majorId: null,
        classesId: null
      },
      changeCampusId: '', // 批量迁移校区
      visibleMoveCampusDialog: false, // 一键毕业 弹窗显示
      visibleGradientDialog: false, // 一键毕业 弹窗显示
      stuStatusList: this.$store.state.systemParam.stuStatus,
      rowCheckList: [],
      rules: {
        grade: { required: true, message: '请选择年级', trigger: 'blur' },
        majorId: { required: true, message: '请选择专业', trigger: 'blur' }
      }
    }
  },
  methods: {
    // 获取学生列表
    async renderTable (pageNum) {
      if (!this.batchId) {
        return false
      } else {
        this.queryInfo.batchId = this.batchId
      }
      await this.getTableData(pageStuByBatchIdApi, pageNum)
    },
    handleUnbind: throttle(async function (row) {
      this.$confirm(`是否确定解绑学生${row.stuName}?`).then(async () => {
        this.loading = true
        try {
          await unbindStuByBatchPayModelApi(row.id)
          this.$message.success('解绑成功!')
          await this.renderTable()
        } catch (e) {
        } finally {
          this.loading = false
        }
      })
    }, 500, {}),
    // 获取班级
    getClassListByMajorId (majorId) {
      this.$http
        .getClassListQuery({
          majorId: majorId
        })
        .then((res) => {
          if (this.insertSelectAll.includes('class')) {
            this.majorList = [
              {
                id: '',
                majorName: '所有班级'
              },
              ...res.data
            ]
          } else {
            this.classList = res.data
          }
        })
    },
    // 显示 一键毕业的弹窗
    showBatchGradientDialog () {
      const _date = new Date().getFullYear()
      // 校验参数合规性
      let isError = false
      let errMsg = ''
      if (_date === Number(this.queryInfo.grade)) {
        isError = true
        errMsg = `无法 对当年度(${_date})入学的学生进行毕业操作！`
      } else if (Number(this.queryInfo.stuStatus) === 2) {
        isError = true
        errMsg = '不能对已毕业学生再次进行毕业操作'
      } else if (this.rowCheckList.length === 0 && !this.queryInfo.collegeId) {
        isError = true
        errMsg = '请勾选需要毕业的学生或者选择学院/专业后，再进行一键毕业操作！'
      }
      if (isError) {
        this.$message({
          showClose: true,
          type: 'error',
          message: errMsg
        })
        return
      }
      // 开始批量操作
      let _msg
      const _query = {
        collegeId: null,
        majorId: null,
        grade: null,
        stuStatus: null,
        stuIds: null
      }
      if (this.rowCheckList.length) {
        _query.stuIds = [...this.rowCheckList]
        _msg = `选中的 <b class="bg-danger">${_query.stuIds.length} 个</b>`
      } else {
        ({
          collegeId: _query.collegeId,
          majorId: _query.majorId,
          grade: _query.grade,
          stuStatus: _query.stuStatus
        } = this.queryInfo)
        const _queryLabels = this.getSearchLabels()
        _msg = ` <b class="danger">${_queryLabels}</b> 的`
      }
      this.visibleGradientDialog = true
      this.$nextTick(() => {
        this.$refs.confirmGradientDialogRef.formData = _query
        this.$refs.confirmGradientDialogRef.message = `<p class="confirm-message">一键毕业处理过的学生学籍状态更改为“已毕业”，同时将清除宿舍，数据将无法还原。<br/>是否确定将${_msg}学生设置为“<b>已毕业</b>”状态？</p>`
      })
    },
    // table 多选控制,获取选中的id
    handleSelectChange (val) {
      while (this.rowCheckList.length) {
        this.rowCheckList.pop()
      }
      if (val.length) {
        val.forEach(({ id }) => {
          this.rowCheckList.push(id)
        })
      }
    }
  }
}
</script>

<style lang="scss">
.bind-student-view {
  //height: calc(100vh - 220px);
  overflow: auto;
}
</style>
