<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        ref="searchFormRef"
        :queryInfo.sync="queryInfo"
        :tool-list="['keyword']"
        :show-clear-btn="false"
        :filter-keys="['orgaType']"
        @on-search="renderTable(1)" />
      <div class="header-button">
        <template v-role="['开发人员']">
          <div v-if="orgaType==='zhxy'" style="margin-top: 4px;">
            <span class="mr-1" style="font-size:14px;vertical-align: -1px;">开启老生缴费</span>
            <el-switch
              v-model="oldStuPayInfo.paramValue"
              @change="handleOldStuPay($event)"
              active-color="var(--color-success)"
              active-text="是"
              inactive-text="否" />
          </div>
        </template>
        <el-button size="small" @click="showSubDialog('visiblePayTypeDialog','payTypeDialogRef')">缴费类型</el-button>
        <el-button v-permission="['batchPay:model:edit', 'upgraded:college:payType:edit']" type="primary" size="small"
                   @click="showDialog()">
          {{ orgaType === 'zhxy' ? '新增自定义缴费批次' : '新增专升本缴费批次' }}
        </el-button>
      </div>
    </template>
    <!--  body  -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="60" align="center" />
      <el-table-column prop="batchName" label="自定义缴批次名称" min-width="250" />
      <el-table-column prop="dailyTypeName" label="绑定缴费类型" min-width="200" />
      <el-table-column prop="dailyTypeAmount" label="绑定金额" width="150" />
      <el-table-column prop="expiredTime" label="过期时间" width="180" />
      <el-table-column prop="remarks" label="备注" width="180" />
      <el-table-column width="290" fixed="right" label="操作">
        <template v-slot="{row}">
          <div v-permission="['batchPay:model:edit', 'upgraded:college:payType:edit']" style="display: inline-block;">
            <el-button type="primary" size="mini" @click="showDialog(row.id)">编辑</el-button>
            <el-button type="primary" size="mini"
                       v-permission="['batchPay:model:bind', 'upgraded:college:payType:bind']"
                       @click="showSubDialog('visibleBindStuDialog','bindStuDialogRef',row.id, row.batchName)">绑定学生
            </el-button>
            <el-button type="primary" size="mini" @click="sendMsg(row)" title="只给未缴费的学生推送消息,可以无限推送。">
              推送通知
            </el-button>
          </div>
          <el-button v-permission="['batchPay:model:del','upgraded:college:payType:del']" type="danger" size="mini"
                     @click="del(delBatchPayModelApi,row.id,`自定义缴费批次-${row.batchName}(${row.dailyTypeName})`)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 缴费类型 dialog -->
    <batch-pay-type-dialog ref="payTypeDialogRef" :visible.sync="visiblePayTypeDialog"
                           v-if="visiblePayTypeDialog" :orga-type="orgaType" />
    <!--  自定义缴费批次/专升本缴费批次 dialog   -->
    <batch-pay-model-dialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog"
                            @on-close="renderTable" :orga-type="orgaType" />
    <!--    绑定学生 dialog -->
    <batch-pay-model-bind-stu-dialog ref="bindStuDialogRef" :visible.sync="visibleBindStuDialog"
                                     v-if="visibleBindStuDialog" />
    <!--  批量绑定多个缴费项目 dialog  -->
    <batch-bind-dialog ref="batchBindDialogRef" :visible.sync="visibleBatchBindDialog" v-if="visibleBatchBindDialog" />
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import BatchPayModelBindStuDialog
  from '@/views/pages/financeManage/batch-pay-model/component/batch-pay-model-bind-stu-dialog/batchPayModelBindStuDialog.vue'
import BatchPayTypeDialog from '@/views/pages/financeManage/batch-pay-model/component/batchPayTypeDialog.vue'
import BatchPayModelDialog from '@/views/pages/financeManage/batch-pay-model/component/batchPayModelDialog.vue'
import { listDailyPayTypeApi } from '@/api/finance-manage/pay-type-api'
import { delBatchPayModelApi, pageBatchPayModelApi, sendPayMessageApi } from '@/api/finance-manage/custom-pay-api'
import BatchBindDialog from '@/views/pages/financeManage/batch-pay-model/component/batchBindDialog.vue'
import { addParams } from '@/api/sysParams'

export default {
  name: 'batchPayModel',
  components: {
    BatchBindDialog,
    BatchPayModelBindStuDialog,
    BatchPayTypeDialog,
    BatchPayModelDialog
  },
  mixins: [tableView],
  data () {
    return {
      PayTypeList: [],
      visiblePayTypeDialog: false,
      visibleBindStuDialog: false, // 绑定和查看学生
      visibleBatchBindStuDialog: false, // 多批次批量绑定学生
      oldStuPayInfo: {},
      visibleBatchBindDialog: false, // 批量绑定多个缴费项目
      queryInfo: {
        batchId: null, // 自定义缴费模板 id
        classesIds: [], // 班级id, 可多选
        collegeId: null,
        dailyPayTypeId: null,
        majorId: null,
        orgaType: 'zhxy' // zhxy-自定义缴费批次(财务处); yuanwei-专升本缴费(招生办)
      },
      orgaType: '' // 缴费类型
    }
  },
  watch: {
    $route: {
      handler (to, from) {
        if (to.path === '/recruitManage/educationImpPay') {
          // 招生办-专升本缴费模块
          this.orgaType = 'yuanwei'
        } else if (to.path === '/financeManage/batchPayModel') {
          // 财务处-自定义缴费批次模块
          this.orgaType = 'zhxy'
        }
        if (this.$refs.searchFormRef) this.$refs.searchFormRef.clear()
        this.renderTable(1)
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.getDailyTradeTypeList()
    this.getIsOldPayFlag()
  },
  methods: {
    delBatchPayModelApi, // 删除api
    // 获取学生列表
    async renderTable (pageNum) {
      this.queryInfo.orgaType = this.orgaType
      await this.getTableData(pageBatchPayModelApi, pageNum)
    },
    // 日常费用 的缴费类型  type=2 自定义
    async getDailyTradeTypeList () {
      try {
        const res = await listDailyPayTypeApi('2')
        this.PayTypeList = res.data
      } catch (e) {
      }
    },
    // 获取老生缴费的系统字典
    async getIsOldPayFlag () {
      try {
        const res = await this.$http.getSysDictionaryByKey('isOldStuPay')
        this.oldStuPayInfo = res.data[0]
        //   paramValue存的格式是string,因此要转为boolean
        this.oldStuPayInfo.paramValue = Boolean(this.oldStuPayInfo.paramValue)
      } catch (e) {
      }
    },
    sendMsg (row) {
      this.$confirm(`<p class="confirm-message">是否确定向绑定了 <b class="primary">${row.batchName}(缴费类型:${row.dailyTypeName})</b> 的所有学生推送缴费通知?</p>`, '推送通知', {
        dangerouslyUseHTMLString: true
      }).then(async () => {
        try {
          await sendPayMessageApi(row.id)
          this.$message.success('推送成功!')
        } catch (e) {
        }
      }).catch(() => {
        this.$message('已取消推送。')
      })
    },
    // 修改老生缴费的系统字典 paramValue
    async handleOldStuPay (val) {
      const _flag = Boolean(val)
      const _msg = _flag ? '开启' : '关闭'
      this.$confirm(`是否确定${_msg}老生缴费?`).then(async () => {
        this.loading = true
        try {
          await addParams({
            paramValue: val.toString(),
            id: this.oldStuPayInfo.id
          })
          this.$message.success(`已${_msg}!`)
        } catch (e) {
          this.oldStuPayInfo.paramValue = !this.oldStuPayInfo.paramValue
        } finally {
          this.loading = false
        }
      }).catch(() => {
        this.oldStuPayInfo.paramValue = !this.oldStuPayInfo.paramValue
      })
    }
  }
}
</script>
