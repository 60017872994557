<!-- 待绑定的学生 -->
<template>
  <div v-loading="loading" class="page-container unbind-student-view flex-column oh">
    <div class="flex-not-shrink" style="padding: 10px 0 0;">
      <tool-tips-group tips-content="可以直接根据【搜索条件】批量绑定，也可以勾选学生进行批量绑定。">
        <view-search-form
          ref="searchFormRef"
          keyword-placeholder="姓名、学号、录取编号"
          :query-info.sync="tableQuery"
          :tool-list="['college','major','class','keyword']"
          @on-search="renderTable"
          @on-clear="clearTable"
          style="display: inline-block;" />
      </tool-tips-group>
      <div class="header-button" style="padding-right: 54px;">
        <upload-excel :permit-key="['class:room:edit']"
                      download-title="下载导入学生模板"
                      import-title="批量导入学生"
                      :loading.sync="loading"
                      :api="$http.importExcelHttp.importPayModelBindStudent"
                      :query="batchId"
                      @on-download="$http.exportExcelHttp.exportStuPayTypeTemplate()"/>
      </div>
      <p class="font-grey mb-1" style="margin-top: 0;font-size: 13px;">搜索结果: {{ tableData.length }} 人</p>
    </div>
    <!-- body -->
    <div class="flex-column unbind-student-view__body flex flex-1 oh">
      <el-table :data="tableData" empty-text="请先选择院系、专业" style="width: 100%" height="auto" border stripe
                @selection-change="handleSelectChange">
        <el-table-column type="selection" width="50" align="center" />
        <el-table-column type="index" label="ID" width="100" />
        <el-table-column prop="idNumber" label="身份证" width="190" />
        <el-table-column prop="stuName" label="姓名" width="120" />
        <el-table-column prop="campusName" label="校区" width="150" />
        <el-table-column prop="collegeName" label="院系" width="150" />
        <el-table-column prop="majorName" label="专业" width="150" />
        <el-table-column prop="className" label="班级" min-width="150">
          <template v-slot="{row}">
            {{ row.className || '未绑定' }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="unbind-student-view__footer flex-not-shrink">
      <el-button @click="$emit('on-close')">关闭</el-button>
      <el-button type="primary"
                 :disabled="(!tableQuery.classesId&&!tableQuery.majorId&&!rowCheckList.length)? true : false"
                 @click="handleBindStudent">批量绑定
      </el-button>
    </div>
  </div>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { getStudentListQuery } from '@/api/student'
import { bindStuByBatchPayModelApi } from '@/api/finance-manage/custom-pay-api'

export default {
  name: 'unbindStudentView',
  mixins: [tableView],
  props: {
    batchId: String,
    title: String,
    orgaType: String
  },
  data () {
    return {
      tableQuery: { // queryInfo
        loadFlag: true,
        collegeId: this.$store.state.login.userInfo.collegeId || null,
        keyword: null,
        majorId: null,
        classesId: null,
        batchId: ''
      },
      rowCheckList: []
    }
  },
  methods: {
    // 获取学生列表
    async renderTable () {
      this.clearTable()
      if (!this.tableQuery.majorId && !this.tableQuery.classesId && !this.tableQuery.keyword) {
        this.$message('请先选择专业或者班级或者输入学生名后再进行搜索。')
        return
      }
      if (this.tableQuery.keyword && !isNaN(this.tableQuery.keyword) && this.tableQuery.keyword.length < 8) {
        this.$message('学号/考号搜索请最少输入7位数字.')
        return
      }
      this.loading = true
      this.tableQuery.batchId = this.batchId
      try {
        this.queryInfo.batchId = this.batchId
        const res = await getStudentListQuery(this.tableQuery)
        this.tableData = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    // table 多选控制,获取选中的id
    handleSelectChange (val) {
      while (this.rowCheckList.length) {
        this.rowCheckList.pop()
      }
      if (val.length) {
        val.forEach(({ userId }) => {
          this.rowCheckList.push(userId)
        })
      }
    },
    handleBindStudent () {
      if (!this.batchId) {
        this.$message.error('系统出错,请稍后重试！')
        this.$emit('on-close')
        return
      }
      const _query = {
        batchId: this.batchId
      }
      let _confirmMsg = '是否绑定?'
      if (this.rowCheckList.length) {
        _query.userIds = this.rowCheckList
        _confirmMsg = `是否确定选中的 <b class="bg-primary" style="padding-left:3px;"> ${this.rowCheckList.length}个</b> `
      } else {
        ({
          majorId: _query.majorId,
          classesId: _query.classId
        } = this.tableQuery)
        const _queryLabels = this.getSearchLabels()
        _confirmMsg = `是否确定将 <b class="primary">${_queryLabels}</b> 的`
      }
      this.$confirm(`<p class="confirm-message">${_confirmMsg}学生绑定到${this.orgaType === 'zhxy' ? '自定义缴费' : '专升本缴费'}项目-<b>${this.title}</b>中？</p>`, '绑定缴费项目', {
        dangerouslyUseHTMLString: true,
        type: 'primary'
      }).then(async () => {
        this.loading = true
        try {
          await bindStuByBatchPayModelApi(_query)
          this.$message.success('绑定成功!')
          await this.renderTable()
        } catch (e) {
        } finally {
          this.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss">
.unbind-student-view {
  height: calc(100vh - 160px);

  .tool-tips-group {
    .ques-btn.el-button {
      margin-left: 10px;
      height: 40px;
    }
  }

  .unbind-student-view__footer {
    padding: 10px 0;
    text-align: center;
  }
}
</style>
