<!--自定义缴费批次 缴费类型 弹窗 type=2 -->
<template>
  <el-dialog
    class="batch-pay-type-dialog"
    :title="`缴费类型(${title})`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="890px"
    @close="close">
    <table-view
      :total="total"
      :query-info.sync="queryInfo"
      style="padding-top: 0;"
      @reload-table="renderTable">
      <template #header>
        <div class="mb-1" style="text-align: right">
          <el-button v-permission="['batchPay:model:payType:edit','upgraded:college:payType:edit']" size="small"
                     type="primary" @click="newRow"
                     style="margin-right: 1rem;">新增缴费类型
          </el-button>
        </div>
      </template>
      <el-form ref="formRef" :model="formData" :rules="rules">
        <el-table :data="formData.tableData" height="400px">
          <el-table-column label="序号" type="index" width="50px" align="center" />
          <el-table-column label="金额" width="180px">
            <template v-slot="scope">
              <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.amount`" :rules="rules.amount">
                <el-input size="small" v-model="scope.row.amount" />
              </el-form-item>
              <span v-else>{{ scope.row.amount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="缴费名称">
            <template v-slot="scope">
              <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.name`" :rules="rules.name">
                <el-input size="small" v-model="scope.row.name" />
              </el-form-item>
              <span v-else>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注" width="140px">
            <template v-slot="scope">
              <el-form-item v-if="scope.row.isEditing" :prop="`tableData.${scope.$index}.remarks`">
                <el-input size="small" v-model="scope.row.remarks" />
              </el-form-item>
              <span v-else>{{ scope.row.remarks }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="160px">
            <template v-slot="scope">
              <template v-if="scope.row.isEditing">
                <el-form-item>
                  <el-button size="small" @click="cancel(scope)">取消</el-button>
                  <el-button v-permission="['batchPay:model:payType:edit','upgraded:college:payType:edit']"
                             type="primary" size="small"
                             v-if="scope.row.isEditing" @click="save(scope)">保存
                  </el-button>
                </el-form-item>
              </template>
              <template v-else>
                <template v-if="!isEditing">
                  <el-button v-permission="['batchPay:model:payType:del', 'upgraded:college:payType:del']" size="small"
                             @click="delRow(scope.row.id)">
                    删除
                  </el-button>
                  <el-button v-permission="['batchPay:model:payType:edit','upgraded:college:payType:edit']" size="small"
                             type="primary" plain
                             @click="editRow(scope)">编辑
                  </el-button>
                </template>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </table-view>
  </el-dialog>
</template>

<script>
import Vue from 'vue'
import dialog from '@/vue/mixins/dialog'
import { delDailyPayTypeApi, pageDailyPayTypeApi, saveDailyPayTypeApi } from '@/api/finance-manage/pay-type-api'
import tableView from '@/vue/mixins/table-view'

export default {
  name: 'batchPayTypeDialog',
  mixins: [dialog, tableView],
  props: {
    orgaType: String
  },
  data () {
    return {
      queryInfo: {
        pageSize: 20,
        type: 2 // 自定义缴费批次 type=2; 专升本缴费 type=3
      },
      formData: {
        tableData: []
      },
      rules: {
        amount: [
          {
            required: true,
            message: '请填写金额',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.validatorTwoDecimalPlacesObj,
          {
            validator: (rule, value, callback) => {
              if (value === 0) {
                return callback(new Error('金额必须大于0!'))
              } else {
                return callback()
              }
            },
            trigger: 'blur'
          }
        ],
        name: {
          required: true,
          message: '请填写缴费名称',
          trigger: 'blur'
        }
      },
      isEditing: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.title = this.orgaType === 'zhxy' ? '自定义缴费' : '专升本缴费'
      this.queryInfo.type = this.orgaType === 'zhxy' ? 2 : 3
      this.renderTable(1)
    })
  },
  methods: {
    newRow () {
      if (this.isEditing) {
        this.$message('请先保存已修改的数据，再进行新增操作！')
      } else {
        const _row = {
          amount: 0,
          name: '',
          remarks: '',
          isEditing: true
        }
        this.formData.tableData.unshift(_row)
        this.isEditing = true
      }
    },
    editRow (scope) {
      this.isEditing = true
      scope.row.isEditing = true
      Vue.set(this.formData.tableData, scope.$index, scope.row)
    },
    async renderTable (pageNum) {
      if (pageNum) this.queryInfo.pageNum = pageNum
      try {
        const res = await pageDailyPayTypeApi(this.queryInfo)
        this.formData.tableData = res.data.list
        this.formData.tableData.map(row => Object.assign(row, { isEditing: false }))
        this.total = res.data.total
      } catch (e) {
      }
    },
    delRow (id) {
      this.$confirm('点击确定将删除缴费类型。', '删除').then(async () => {
        try {
          await delDailyPayTypeApi(id)
          await this.renderTable()
        } catch (e) {
        }
      })
    },
    async cancel (scope) {
      scope.row.isEditing = false
      this.isEditing = false
      Vue.set(this.formData.tableData, scope.$index, scope.row)
      await this.renderTable()
    },
    save (scope) {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          this.$confirm(`点击确定保存${this.title}类型。`, '保存').then(async () => {
            try {
              // 自定义缴费批次中的 type=2; 专升本缴费 type=3
              Object.assign(scope.row, { type: this.queryInfo.type })
              await saveDailyPayTypeApi(scope.row)
            } catch (e) {
            }
          }).catch(() => {
            this.cancel(scope)
          }).finally(() => {
            this.renderTable()
            this.isEditing = false
          })
        }
      })
    }
  }
}
</script>
<style lang="scss">
.batch-pay-type-dialog {
  .el-dialog__body {
    padding-top: 10px;

    .table-view {
      .el-form {
        padding: 0;
      }
    }
  }
}
</style>
