<!-- 自定义缴费批次 添加/编辑 -->
<template>
  <el-dialog
    class="batch-pay-model-dialog"
    :title="`${preTitle}${title}批次`"
    :visible.sync="show"
    :close-on-click-modal="false"
    :before-close="close"
    width="520px"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="120px">
      <el-form-item prop="batchName" label="名称">
        <el-input v-model.trim="formData.batchName" />
      </el-form-item>
      <!-- 缴费类型 自定义项目 显示  -->
      <el-form-item label="绑定缴费类型" prop="dailyPayTypeId">
        <tool-tips-group is-flex :tips-content="`数据来自“${title}-缴费类型弹窗”。`">
          <el-select v-model="formData.dailyPayTypeId" :disabled="!!id">
            <el-option
              v-for="{name,id} in PayTypeList"
              :label="name"
              :value="id"
              :key="id" />
          </el-select>
        </tool-tips-group>
      </el-form-item>
      <el-form-item label="过期时间" prop="expiredTime">
        <el-date-picker
          type="datetime"
          placeholder="过期时间"
          v-model="formData.expiredTime"
          style="width: 100%"
          value-format="yyyy-MM-dd HH:mm:ss"
        />
      </el-form-item>
      <el-form-item prop="remarks" label="备注">
        <el-input v-model.trim="formData.remarks" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save(`${title}批次`,saveBatchPayModelApi)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { listDailyPayTypeApi } from '@/api/finance-manage/pay-type-api'
import { getBatchPayModelByIdApi, saveBatchPayModelApi } from '@/api/finance-manage/custom-pay-api'

export default {
  name: 'batchPayModelDialog',
  mixins: [dialog],
  props: {
    orgaType: String
  },
  data () {
    return {
      PayTypeList: [],
      formData: {
        id: null,
        batchName: '',
        dailyPayTypeId: null,
        expiredTime: '',
        remarks: '',
        orgaType: 'zhxy' // zhxy-自定义缴费批次(财务处); yuanwei-专升本缴费(招生办)
      },
      rules: {
        batchName: [this.$store.state.commonValidate.limit30WordsObj,
          {
            required: true,
            message: '请填写缴费批次的名称',
            trigger: 'blur'
          }],
        dailyPayTypeId: {
          required: true,
          message: '请选择要绑定的缴费类型',
          trigger: 'blur'
        },
        expiredTime: {
          required: true,
          message: '请选择过期时间',
          trigger: 'blur'
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.title = this.orgaType === 'zhxy' ? '自定义缴费' : '专升本缴费'
      this.getDailyTradeTypeList()
      if (this.id) {
        this.getDataById(this.id, getBatchPayModelByIdApi)
      } else {
        this.formData.orgaType = this.orgaType
      }
    })
  },
  methods: {
    saveBatchPayModelApi, // 保存api
    // 日常费用 的缴费类型  type=2 自定义
    async getDailyTradeTypeList () {
      if (this.PayTypeList.length) return
      try {
        const _type = this.orgaType === 'zhxy' ? '2' : '3'
        const res = await listDailyPayTypeApi(_type)
        this.PayTypeList = res.data
      } catch (e) {
      }
    }
  }
}
</script>
